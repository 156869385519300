import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./Tabs.module.scss";

class Tabs extends Component {
	static propTypes = {
		tabData: PropTypes.arrayOf(
			PropTypes.shape({
				id: PropTypes.string,
				header: PropTypes.string,
				content: PropTypes.shape()
			})
		).isRequired,
		classes: PropTypes.string,
		tabTitle: PropTypes.string
	};

	static defaultProps = {
		classes: "",
		tabTitle: ""
	};

	constructor(props) {
		super(props);
		this.state = {
			selectedTabId: this.props.tabData[0].id
		};
	}

	toggleTab = id => {
		this.setState({
			selectedTabId: id
		});
	};

	generateTabHeaders = () => {
		const tabHeaderList = this.props.tabData.map(tab => {
			const isActive =
				this.state.selectedTabId === tab.id ? "is-active" : "";

			return (
				<li key={tab.id} className={`${isActive}`}>
					<a
						role="button"
						tabIndex="0"
						href="#!"
						onClick={() => this.toggleTab(tab.id)}
					>
						<span>{tab.header}</span>
					</a>
				</li>
			);
		});

		return (
			<div className="nonBulmaTab tabs is-toggle">
				<div>
					<ul>
						<li></li>
						{tabHeaderList}
					</ul>
				</div>
			</div>
		);
	};

	generateTabContent = () => {
		const tabContent = this.props.tabData.map(tab => {
			const isActive =
				this.state.selectedTabId === tab.id ? "" : "hidden";

			return (
				<div
					className={`${isActive} ${styles.tabPipeOuter}`}
					key={tab.id}
				>
					<div className={`${styles.tabPipeInner} p-b-lg  p-t-lg`}>
						{tab.content}
					</div>
				</div>
			);
		});
		return tabContent;
	};

	render() {
		const tabHeaders = this.generateTabHeaders();
		const tabContents = this.generateTabContent();

		return (
			<div className={`${this.props.classes} containerPipe container`}>
				{tabHeaders}
				{tabContents}
			</div>
		);
	}
}

export default Tabs;
