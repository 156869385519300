import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { graphql, Link } from "gatsby";

// Local imports
import Layout from "src/layouts/LayoutWhiteHeader";
import StripPrimary from "src/components/generic/StripPrimary/StripPrimary";
import StripTitle from "src/components/generic/StripTitle/StripTitle";
import StripsAlternatingContent from "src/components/generic/StripsAlternatingContent/StripsAlternatingContent";
import SEO from "src/components/generic/SEO/SEO";
import config from "data/SiteConfig";
import Tabs from "src/components/generic/Tabs/Tabs";

// Import videos
import speedVid from "static/images/pages/dashboard_comparison_sped_up.mp4";

// Styles
// import styles from "./index.module.scss";

const stripPrimary = {
	title: "Reporting",
	subTitle: (
		<p>
			With a library of pre-made reports and the ability to build more
			quickly and easily we&apos;ll make your reporting best in class.
		</p>
	)
};

const alternatingContent = [
	{
		subTitle: "Explore some of our reporting templates.",
		content: (
			<React.Fragment>
				<p>
					We&apos;ve anonymised the data for some reports so we can
					share them publically.
				</p>
			</React.Fragment>
		),
		secondContent: (
			<React.Fragment>
				<div className="reportLink">
					<h3 className="is-size-5">
						<a
							href="https://lookerstudio.google.com/u/0/reporting/6848ee68-c73c-40a5-99bb-90da18fc26d5/"
							target="blank"
							rel="noopener noreferrer"
						>
							Branded SERP Monitoring
						</a>
					</h3>
					<span>Monitor and control your brand image on Google.</span>
				</div>
				<div className="reportLink">
					<h3 className="is-size-5">
						<a
							href="https://lookerstudio.google.com/u/0/reporting/a2c4a21e-26a3-4cea-9348-d9d9d94c6535/"
							target="blank"
							rel="noopener noreferrer"
						>
							Rank Tracking
						</a>
					</h3>
					<span>Analyse rich rank tracking data.</span>
				</div>
				<div className="reportLink">
					<h3 className="is-size-5">
						<a
							href="https://lookerstudio.google.com/u/0/reporting/322d75af-be9f-48c7-9584-352d90d14dac/"
							target="blank"
							rel="noopener noreferrer"
						>
							Analytics Performance
						</a>
					</h3>
					<span>
						A base template for measuring SEO performance with
						analytics.
					</span>
				</div>
				<div className="reportLink">
					<h3 className="is-size-5">
						<a
							href="https://lookerstudio.google.com/u/0/reporting/cc80e9af-db4b-41e0-98a7-8ee29ab5d537/"
							target="blank"
							rel="noopener noreferrer"
						>
							Log Monitoring
						</a>
					</h3>
					<span>Monitor your logs for technical issues.</span>
				</div>
				<div className="reportLink">
					<h3 className="is-size-5">
						<a
							href="https://lookerstudio.google.com/u/0/reporting/88c1fbe6-9866-45d6-bbfd-23facace4474/"
							target="blank"
							rel="noopener noreferrer"
						>
							Core Web Vitals
						</a>
					</h3>
					<span>Track your domain and competitors CWV.</span>
				</div>
			</React.Fragment>
		)
	},
	{
		subTitle: "Our reports are fast.",
		content: (
			<React.Fragment>
				<p>
					By building out pipelines for the data and avoiding native
					connectors we make reports that run a lot faster than your
					normal ones.
				</p>
			</React.Fragment>
		),
		image: null
	},
	{
		subTitle:
			"Our templates are built in data studio but sources can be used anywhere.",
		content: (
			<React.Fragment>
				<p>
					We build the templates in Data Studio, but the business
					logic lives in the underlying table.
				</p>
				<p>
					We provide the table so you can recreate any report in the
					reporting platform of your choice.
				</p>
			</React.Fragment>
		),
		image: null
	},
	{
		subTitle: "You can quickly and powerfully build custom reports",
		content: (
			<React.Fragment>
				<p>
					You don&apos;t have to use our reports, you can also build
					your own.
				</p>
				<p>
					With Piped Out you can just fill in a form, and you&apos;ll
					have data ready to plug into whatever reporting platform
					you&apos;re using.
				</p>
			</React.Fragment>
		),
		image: null
	},
	{
		subTitle: "Need custom reports and don't know how to build them?",
		content: (
			<React.Fragment>
				<p>
					If you&apos;ve got specific requests and reporting needs
					which default templates don&apos;t meet.
				</p>
			</React.Fragment>
		),
		image: null
	}
];

const propTypes = {
	data: PropTypes.shape().isRequired,
	location: PropTypes.shape().isRequired
};

const Index = ({ data, location }) => {
	// Set images
	alternatingContent[1].video = speedVid;
	alternatingContent[2].image = data.alternatingImg3.childImageSharp.fluid;
	alternatingContent[3].image = data.alternatingImg2.childImageSharp.fluid;

	const title = "Working with data in the warehouse";
	const description =
		"Now the data is loaded, we&apos;ve got huge amounts of data at our fingertips. We need working with it to be quick and painless, so we can get to problem solving and doing our jobs.";

	return (
		<Layout>
			<Helmet title={config.siteTitle} />
			<SEO pageTitle={title} pageDescription={description} />
			<StripPrimary
				title={stripPrimary.title}
				subTitle={stripPrimary.subTitle}
				fullSized={false}
				isWhite={true}
			/>
			<section>
				{/* <StripTitle title="How does analysing data work?" /> */}
				<StripsAlternatingContent
					stripList={alternatingContent}
					classes="m-t-lg"
				/>
			</section>
		</Layout>
	);
};

Index.propTypes = propTypes;

export default Index;

// The graphql query made here will automatically be entered as the
// prop data in data
export const pageQuery = graphql`
	query ReportingData {
		# Get us the main alternating images
		alternatingImg1: file(
			relativePath: {
				eq: "images/pages/how_does_it_work/save_query_results.jpg"
			}
		) {
			childImageSharp {
				fluid(maxWidth: 681, maxHeight: 632) {
					...GatsbyImageSharpFluid
				}
			}
		}
		alternatingImg2: file(
			relativePath: {
				eq: "images/pages/how_does_it_work/query_builder.jpg"
			}
		) {
			childImageSharp {
				fluid(maxWidth: 916, maxHeight: 705) {
					...GatsbyImageSharpFluid
				}
			}
		}
		alternatingImg3: file(
			relativePath: {
				eq: "images/pages/how_does_it_work/business_logic.jpg"
			}
		) {
			childImageSharp {
				fluid(maxWidth: 1051, maxHeight: 866) {
					...GatsbyImageSharpFluid
				}
			}
		}
	}
`;
